/* --- MOBILE STYLES --- */

.landing .name {
  font-size: 48px;
  letter-spacing: -3px;
}
.landing .description {
  font-size: 22px;
  letter-spacing: 1.5px;
}

.landingImage {
  right: 0;
  width: auto;
  height: auto;
  bottom: 36%;
}

.landing .socialIcons {
  display: flex;
  position: absolute;
  left: 50px;
  bottom: 20px;
}

/* --- MEDIA QUERIES --- */

@media (min-width: 750px) {

  .textContainer {
    margin-top: 60px;
  }

  .landing .name {
    font-size: 70px;
  }

  .landing .description {
    font-size: 32px;
  }

  .landingImage {
    right: 20%;
    width: auto;
    height: auto;
    bottom: 40%;
  }

  .landing .socialIcons {
    flex-direction: column;
  }
}


@media (max-width: 430px) {

  .textContainer {
    margin-top: 50px;
  }


  .landingImage {
    right: 10%;
    width: auto;
    height: 47%;
    bottom: 40%;
  }
}
